    // Switch

    $('.item-switch-primay input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-primary');
        else
            dad.removeClass('switch-primary');
    });
    
    $('.item-switch-error input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-error');
        else
            dad.removeClass('switch-error');
    });
    
    $('.item-switch-success input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-success');
        else
            dad.removeClass('switch-success');
    });
    
    $('.item-switch-warning input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-warning');
        else
            dad.removeClass('switch-warning');
    });
    
    // switch-textDM
    $('.item-switch-textDM-primary input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-textDM-primary');
        else
            dad.removeClass('switch-textDM-primary');
    });
    
    $('.item-switch-textDM-error input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-textDM-error');
        else
            dad.removeClass('switch-textDM-error');
    });
    
    
    $('.item-switch-textDM-success input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-textDM-success');
        else
            dad.removeClass('switch-textDM-success');
    });
    
    
    $('.item-switch-textDM-warning input').on('change', function () {
        var dad = $(this).parent();
        if ($(this).is(':checked'))
            dad.addClass('switch-textDM-warning');
        else
            dad.removeClass('switch-textDM-warning');
    });
    
    
    // End Switch