// Clear Textfiel

jQuery(function ($) {

    function tog(ClearTextfielDui) {
        return ClearTextfielDui ? 'addClass' : 'removeClass';
    }

    $(document).on('input', '.clear-textfiel',
        function () {
            $(this)[tog(this.value)]('clearDui');
        }).on('mousemove', '.clearDui', function (e) {

        $(this)[tog(
            this.offsetWidth - 34 < e.clientX - this.getBoundingClientRect().left)](
            'OnClearDui');
    }).on('click', '.OnClearDui', function () {
        $(this).removeClass('clearDui OnClearDui').val('');
    });

});
// End Clear Textfiel

 // Clear ClearDatepickerlDui

 jQuery(function ($) {

    function tog(ClearDatepickerlDui) {
        return ClearDatepickerlDui ? 'addClass' : 'removeClass';
    }

    $(document).on('input', '.clear-textfiel',
        function () {
            $(this)[tog(this.value)]('clearDui-datepicker');
        }).on('mousemove', '.clearDui-datepicker', function (e) {

        $(this)[tog(
            this.offsetWidth - 34 < e.clientX - this.getBoundingClientRect().left)](
            'OnClearDui');
    }).on('click', '.OnClearDui', function () {
        $(this).removeClass('OnClearDui').val('');
    });

});
// End Clear ClearDatepickerlDui

// Enable Datepicker
$('.clear-datepicker').keydown(function () {
        //code to not allow any changes to be made to input field
        return false;
    });
// End Enable Datepicker
