$(function () {
    // Toast positions
    const positions = ['left', 'right', 'leftBottom', 'rightBottom'];
  
    positions.forEach(position => {
      const buttonToast = document.querySelector(`#toastDM-${position}`),
            toast = document.querySelector(`.toast-${position}`),
            closeToast = document.querySelector(`.close-${position}`),
            progressToast = document.querySelector(`.progress-${position}-toast`);
  
      let timeout1, timeout2;
  
      buttonToast.addEventListener("click", () => {
        toast.classList.add("activeToast");
        progressToast.classList.add("activeToast");
  
        timeout1 = setTimeout(() => {
          toast.classList.remove("activeToast");
        }, 5000);
  
        timeout2 = setTimeout(() => {
          progressToast.classList.remove("activeToast");
        }, 5300);
      });
  
      closeToast.addEventListener("click", () => {
        toast.classList.remove("activeToast");
  
        setTimeout(() => {
          progressToast.classList.remove("activeToast");
        }, 300);
  
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      });
    });
  });