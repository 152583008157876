$(function () {
    // Snackbar positions
    const positions = ['leftTop', 'rightTop', 'leftBottom', 'rightBottom'];
  
    positions.forEach(position => {
      const buttonSnackbar = document.querySelector(`#snackbarDM-${position}`),
            snackbar = document.querySelector(`.snackbar-${position}`),
            closeSnackbar = document.querySelector(`.closeDM-${position}`),
            progressSnackbar = document.querySelector(`.progress-${position}-snackbar`);
  
      let timeout1, timeout2;
  
      buttonSnackbar.addEventListener("click", () => {
        snackbar.classList.add("activeSnackbar");
        progressSnackbar.classList.add("activeSnackbar");
  
        timeout1 = setTimeout(() => {
          snackbar.classList.remove("activeSnackbar");
        }, 5000);
  
        timeout2 = setTimeout(() => {
          progressSnackbar.classList.remove("activeSnackbar");
        }, 5300);
      });
  
      closeSnackbar.addEventListener("click", () => {
        snackbar.classList.remove("activeSnackbar");
  
        setTimeout(() => {
          progressSnackbar.classList.remove("activeSnackbar");
        }, 300);
  
        clearTimeout(timeout1);
        clearTimeout(timeout2);
      });
    });
  });
  