

// Select
$(function () {
    $('select').each(function () {
        $(this).selectDUI({
            placeholder: $(this).attr('placeholder'),
            allowClear: Boolean($(this).data('allow-clear')),
        });
    });
});

// End select