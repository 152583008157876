// fix modal backdrop and toast

$('.modal').insertAfter($('body'));

$('.show-ToastDM').insertAfter($('body'));
$('.show-snackbarDM').insertAfter($('body'));

// End fix modal backdrop and toast




//  Test Mico
$(function() {
  $(".heart").on("click", function() {
    $(this).toggleClass("is-active");
  });
});

//  End Test Mico

// Tooltips
$('[data-toggle="tooltip"]').tooltip()

// End tooltip


// click tabs slidebar on top

$('.slide-nav-right').on('shown.bs.tab', function (event, slick, direction) {
    $('html, body').animate({
        scrollTop: 0
    }, 0);
});
// End  click tabs slidebar on top

// Menu Collaps

$('.btn-expand-collapse').click(function (e) {
    $('.navbar-primary').toggleClass('collapsed');
});

// End menu Collaps

// Popover
$(function () {
    $('[data-toggle="popover"]').popover()
})

// End Popover









