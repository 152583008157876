
// AppBar Menu
$(function () {
$('.AppBar-Right').click(function () {
    $(".nav-AppBarDM-Right").toggleClass("mobile-nav");
    $(this).toggleClass("is-active");
});

$('.AppBar-Left').click(function () {
    $(".nav-AppBarDM-Left").toggleClass("mobile-nav");
    $(this).toggleClass("is-active");
});

$('.AppBar-Center').click(function () {
    $(".nav-AppBarDM-Center").toggleClass("mobile-nav");
    $(this).toggleClass("is-active");
});


$('.menu-toggle').click(function () {
    $(".nav-menu").toggleClass("mobile-nav");
    $(this).toggleClass("is-active");
});
})
// End AppBar Menu