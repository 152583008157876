// amination progress 

$(document).ready(function () {
    $('.progress .progress-bar').css("width",
        function () {
            return $(this).attr("aria-valuenow") + "%";
        }
    )
});


// amination progress 



// progcess crile

$(function () {
    var $cricle = $('.progress-cricle-success'),
        percent = parseInt($cricle.data('percent')),
        deg = 360 * percent / 100;
    if (percent > 50) {
        $cricle.addClass('success-color-cricle');
    }
    $('.fill-success').css('transform', 'rotate(' + deg + 'deg)');
    $('.percents-success span').html(percent + '%');
});




$(function () {
    var $cricle = $('.progress-cricle-error'),
        percent = parseInt($cricle.data('percent')),
        deg = 360 * percent / 100;
    if (percent > 50) {
        $cricle.addClass('error-color-cricle');
    }
    $('.fill-error').css('transform', 'rotate(' + deg + 'deg)');
    $('.percents-error span').html(percent + '%');
});




$(function () {
    var $cricle = $('.progress-cricle-warning'),
        percent = parseInt($cricle.data('percent')),
        deg = 360 * percent / 100;
    if (percent > 50) {
        $cricle.addClass('warning-color-cricle');
    }
    $('.fill-warning').css('transform', 'rotate(' + deg + 'deg)');
    $('.percents-warning span').html(percent + '%');
});




$(function () {
    var $cricle = $('.progress-cricle-infomation'),
        percent = parseInt($cricle.data('percent')),
        deg = 360 * percent / 100;
    if (percent > 50) {
        $cricle.addClass('infomation-color-cricle');
    }
    $('.fill-infomation').css('transform', 'rotate(' + deg + 'deg)');
    $('.percents-infomation span').html(percent + '%');
});


// End progcess crile
