// Sketon

const $el = $(".item-skeletonDM");

// Start the loop
setInterval(() => {
    $el.removeClass("skeletonDM");
    $el.find(".hide-text").removeClass("hide-text");
},31536000);



// End sketon

