/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import 'alpinejs'
window.moment = require('moment');
window._ = require('lodash');

require('../bootstrap');
require("./component/Progress")
require("./component/Toast")
require("./component/Snackbar")
require("./component/Slider")
require("./component/AppMenu")

require('../plugins');

require("./component/PaginationReponsive")
require("./component/Stepper")
require("./component/Support")
require("./component/Chip")
require("./component/RateEmoji")
require("./component/Switch")
require("./component/ClearTextfiel")
require("./component/FormValidate")
require("./component/SelectDui")
require("./component/SelectMin")
require("./component/TableDataDui")
require("./component/Skenton")



