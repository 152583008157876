

// rate emoij
const rateEmoijList = document.querySelectorAll('.rate-emoij li');

function handleClick(e) {
    const activeItem = document.querySelector('.rate-emoij li.active');
    if (activeItem) {
        activeItem.classList.remove('active');
    }
    this.classList.add('active');
    e.preventDefault();
}

rateEmoijList.forEach(entry => entry.addEventListener('click', handleClick));

// End rate emoij