document.addEventListener('DOMContentLoaded', () => {
    const table = document.querySelector('#table-dataDM');
    const dataTable = new DataTable(table, {
      aaSorting: [],
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 2, targets: -1 },
        { bSortable: false, aTargets: [-1] },
      ],
      oLanguage: { sSearch: '' },
    });
  
    const searchInput = document.querySelector('.dataTables_filter input');
    searchInput.placeholder = 'Search...';
    searchInput.style.width = '200px';
    searchInput.style.height = '40px';
    searchInput.style.display = 'inline-block';
    searchInput.style.border = '1px solid #E4E7EC';
    searchInput.style.borderRadius = '8px';
  
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach(tooltip => tooltip.addEventListener('mouseenter', e => e.target.setAttribute('title', e.target.dataset.originalTitle)));
  });
  