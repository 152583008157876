$(function () {
    // Slider 
    document.querySelectorAll(".RangeDM").forEach(function (el) {
        el.oninput = function () {
            var valPercent = (el.valueAsNumber - parseInt(el.min)) /
                (parseInt(el.max) - parseInt(el.min));
            var style = 'background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(' +
                valPercent + ', #1570ef), color-stop(' + valPercent + ', #f5f6f8));';
            el.style = style;
        };
        el.oninput();
    });


    var sliderEl1 = document.querySelector('.first [data-rangeslider]')
    var sliderEl2 = document.querySelector('.second [data-rangeslider]')
    var sliderEl3 = document.querySelector('.third [data-rangeslider]')

    sliderEl2.addEventListener('input', (ev) => {
        document.querySelector('.second .output').innerHTML = ev.target.value
    })
    sliderEl3.addEventListener('input', (ev) => {
        document.querySelector('.third .output').innerHTML = ev.target.value
    })

    rangesliderJs.create(sliderEl1, {
        min: 0,
        max: 5,
        step: 0.5
    })
    rangesliderJs.create(sliderEl2)
    rangesliderJs.create(sliderEl3, {
        onSlide: function () {
            console.log(this)
        }
    })

    // End slider
});
