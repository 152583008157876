$(document).ready(function () {
    // Fieldsets
    var currentFs, nextFs, previousFs;
    var opacity;
    var current = 1;
    var steps = $('fieldset').length;
  
    setProgressBar(current);
  
    $('.next').click(function () {
      currentFs = $(this).parent();
      nextFs = $(this).parent().next();
  
      // Add Class Active
      $('#progressbar li').eq($('fieldset').index(nextFs)).addClass('active');
  
      // Show the next fieldset
      nextFs.show();
      // Hide the current fieldset with style
      currentFs.animate(
        {
          opacity: 0,
        },
        {
          step: function (now) {
            // For making fieldset appear animation
            opacity = 1 - now;
  
            currentFs.css({
              display: 'none',
              position: 'relative',
            });
            nextFs.css({
              opacity: opacity,
            });
          },
          duration: 1000,
        }
      );
      setProgressBar(++current);
    });
  
    $('.previous').click(function () {
      currentFs = $(this).parent();
      previousFs = $(this).parent().prev();
  
      // Remove class active
      $('#progressbar li').eq($('fieldset').index(currentFs)).removeClass('active');
  
      // Show the previous fieldset
      previousFs.show();
  
      // Hide the current fieldset with style
      currentFs.animate(
        {
          opacity: 0,
        },
        {
          step: function (now) {
            // For making fieldset appear animation
            opacity = 1 - now;
  
            currentFs.css({
              display: 'none',
              position: 'relative',
            });
            previousFs.css({
              opacity: opacity,
            });
          },
          duration: 1000,
        }
      );
      setProgressBar(--current);
    });
  
    function setProgressBar(curStep) {
      var percent = (parseFloat(380 / steps) * curStep).toFixed();
      $('.stepper-progress-barDM').css('width', percent + '%');
    }
  
    $('.submit').click(function () {
      return false;
    });
  });
  